//
// Utility classes
// --------------------------------------------------


// Floats
// -------------------------

.clearfix {
  .clearfix();
}
.center-block {
  .center-block();
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}


// Toggling content
// -------------------------

// Note: Deprecated .hide in favor of .hidden or .sr-only (as appropriate) in v3.0.1
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  .text-hide();
}


// Hide from screenreaders and browsers
//
// Credit: HTML5 Boilerplate

.hidden {
  display: none !important;
}


// For Affix plugin
// -------------------------

.affix {
  position: fixed;
}

//
// Border
//

.border-0        { border: 0 !important; }
.border-top-0    { border-top: 0 !important; }
.border-right-0  { border-right: 0 !important; }
.border-bottom-0 { border-bottom: 0 !important; }
.border-left-0   { border-left: 0 !important; }

//
// Border-radius
//

.rounded {
  border-radius: @border-radius;
}
.rounded-top {
  .border-top-radius(@border-radius);
}
.rounded-right {
  .border-right-radius(@border-radius);
}
.rounded-bottom {
  .border-bottom-radius(@border-radius);
}
.rounded-left {
  .border-left-radius(@border-radius);
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-0 {
  border-radius: 0;
}

.align-baseline    { vertical-align: baseline !important; } // Browser default
.align-top         { vertical-align: top !important; }
.align-middle      { vertical-align: middle !important; }
.align-bottom      { vertical-align: bottom !important; }
.align-text-bottom { vertical-align: text-bottom !important; }
.align-text-top    { vertical-align: text-top !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: @zindex-fixed;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @zindex-fixed;
}

.sticky-top {
  position: fixed;
  top: 0;
  z-index: @zindex-sticky;
}

// Margins And Paddings
// --------------------------
// @sizes: 1,2,3,4,5,10,15,20,25,30,35,40,45,50;
// .make-utils(@sizes);
// .make-utils(@list, @i:1) when (@i =< length(@list)) {
//     @value: extract(@list, @i);
//     .m-@{value} {
//         margin: 10px !important;
//     }
//     .make-utils(@list, @i+1);
// }
@sizes: 1,2,3,4,5,10,12,15,20,25,30,35,40,45,50,60,70,80,90,100;
.make_utils() {
    .-(@i: length(@sizes)) when (@i > 0) {
        @value: extract(@sizes, @i);
        .m-@{value} {margin: e(%("%dpx", @value)) !important;}
        .m-t-@{value} {margin-top: e(%("%dpx", @value)) !important;}
        .m-r-@{value} {margin-right: e(%("%dpx", @value)) !important;}
        .m-b-@{value} {margin-bottom: e(%("%dpx", @value)) !important;}
        .m-l-@{value} {margin-left: e(%("%dpx", @value)) !important;}
        .p-@{value} {padding: e(%("%dpx", @value)) !important;}
        .p-t-@{value} {padding-top: e(%("%dpx", @value)) !important;}
        .p-r-@{value} {padding-right: e(%("%dpx", @value)) !important;}
        .p-b-@{value} {padding-bottom: e(%("%dpx", @value)) !important;}
        .p-l-@{value} {padding-left: e(%("%dpx", @value)) !important;}
       	.-((@i - 1));
    }.-;
};
.make_utils();

@percents: 5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100;
.make_percents() {
    .-(@i: length(@percents)) when (@i > 0) {
        @value: extract(@percents, @i);
        .w-@{value}p {width: e(%("%d%%", @value)) !important;}
       	.-((@i - 1));
    }.-;
};
.make_percents();

.mw-100 { max-width: 100% !important; }
.mh-100 { max-height: 100% !important; }

.text-overflow {
    .text-overflow();
}

.text-hide {
  // font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.img-replace,
.img-replace:link,
.img-replace:visited,
.img-replace:hover,
.img-replace:active {
  .hide-text();
  display: inline-block;
  background-repeat: no-repeat;
}
